import { enableProdMode } from "@angular/core";
import { merge } from "lodash";
import { forkJoin, from, Observable, of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import './app/extension-methods/string.extensions';

export class BootstrapUtils {
    public static boot = (environment: any, blobPath: string = "https://webclients.smartpa.cloud/configuration"): Observable<any> => {

        return BootstrapUtils.fetchJson(`${blobPath}/host-mapping.json`, true).pipe(map((response: any) => {
            let host = window.location.host;
            if (window.location.port) {
                host = host.replace(`:` + window.location.port, "")
            };
            return response[host];
        })).pipe(mergeMap((result: HostMapping) => {
            const applicationName = document.head.getElementsByTagName("base")[0].getAttribute("href").replaceAll("/", "");
            return forkJoin({ hostMapping: of(result), json: BootstrapUtils.fetchJson(`${blobPath}/${applicationName}.json`, false) });
        })).pipe(tap(results => {
            let env = {};
            results.json = results.json.replaceAll("{suffix}", results.hostMapping.suffix);
            results.json = results.json.replaceAll("{api}", `https://api${results.hostMapping.suffix}.smartpa.cloud`);
            const json = JSON.parse(results.json)
            if (json.environments) {
                env = json.environments;
                delete json.environments;
            }
            Object.assign(environment, json);
            let ambientName = results.hostMapping.suffix.replace("-", "")
            if (!ambientName) {
                ambientName = "prod";
            }
            if (env[ambientName]) {
                environment = merge(environment, env[ambientName])
            }
            environment.production = (ambientName == "prod");
            if (environment.production) {
                enableProdMode();
            }
        }))
    }

    private static fetchJson = (url: string, json: boolean): Observable<any> => {
        return from(fetch(url).then(function (response) {
            return json ? response.json() : response.text();
        }))
    }
}

class HostMapping {
    public suffix: string;
}
