<form class="example-form" [formGroup]='userForm'>
  <mat-form-field>
    <input matInput [(ngModel)]="name" formControlName='insertName' placeholder="Insert new Layer name" value="{{this.data.oldName}}" [errorStateMatcher]="errorMatcher">
    <mat-error *ngIf="userForm.hasError('layerNameAlreadyExist')">
        Layer name already exist!
    </mat-error>
  </mat-form-field>
</form>

<mat-dialog-actions>
  <button mat-button mat-dialog-close mat-dialog-close="false">Cancel</button>
  <button [disabled]="!userForm.valid" mat-button mat-dialog-close mat-dialog-close="{{name}}">Save</button>
</mat-dialog-actions>