/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./info-dialog.component";
import * as i8 from "@angular/forms";
var styles_InfoDialogComponent = [i0.styles];
var RenderType_InfoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoDialogComponent, data: {} });
export { RenderType_InfoDialogComponent as RenderType_InfoDialogComponent };
export function View_InfoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-typography"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["fxFlex", "30"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["In mappa sono presenti i principali lavori stradali in corso e in programma a Bologna nelle prossime settimane. In caso di maltempo alcuni dei cantieri potrebbero essere ricalendarizzati. "])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(7, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Chiudi"]))], function (_ck, _v) { var currVal_4 = ""; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).disabled || null); var currVal_1 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 7).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 7).type; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_InfoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-dialog", [], null, null, null, View_InfoDialogComponent_0, RenderType_InfoDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.InfoDialogComponent, [i2.MAT_DIALOG_DATA, i8.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoDialogComponentNgFactory = i1.ɵccf("app-info-dialog", i7.InfoDialogComponent, View_InfoDialogComponent_Host_0, {}, {}, []);
export { InfoDialogComponentNgFactory as InfoDialogComponentNgFactory };
