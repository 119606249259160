import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { BootstrapUtils } from './bootstrap.utils';
import { environment } from './environments/environment';

BootstrapUtils.boot(environment).subscribe(() => {
    let p = platformBrowserDynamic().bootstrapModule(AppModule);
    p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); })
        .catch(err => console.error(err));


});
