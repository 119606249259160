import { GeoFeatureContainer } from './GeoFeature';
export class Authority {
    constructor() {
        this.authorityId = 0;
        this.name = null;
        this.imageUrl = null;
        this.logoUrl = null;
        this.latitude = 0;
        this.longitude = 0;
    }
}
export class User {
    constructor() {
        this.name = null;
        this.roles = [];
        this.id = null;
        this.currentAuthority = null;
    }
    get isBackofficeUser() {
        return this.roles.indexOf("Backoffice") >= 0;
    }
}
export class Settings {
}
Settings.user = new User();
export class Coordinate {
    constructor() {
        this.latitude = 0;
        this.longitude = 0;
    }
}
export class Roadway {
    constructor() {
        this.id = 0;
        this.name = null;
    }
}
export class Neighborhood {
    constructor() {
        this.id = 0;
        this.name = null;
    }
}
export class Period {
    constructor() {
        this.id = 0;
        this.year = 0;
        this.submissionStartDate = null;
        this.submissionEndDate = null;
    }
}
export class Company {
    constructor() {
        this.id = 0;
        this.name = null;
        this.isOperationalUnit = false;
    }
}
export class NewCompany {
    constructor() {
        this.name = null;
        this.user = new NewCompanyUser();
        this.isOperationalUnit = false;
    }
}
export class NewCompanyUser {
    constructor() {
        this.email = null;
        this.firstName = null;
        this.surname = null;
        this.fiscalCode = null;
    }
}
export class RoadWorkBase {
    constructor() {
        this.year = null;
        this.isOverlap = false;
        this.priority = null;
        this.address = null;
        this.addressNumberFrom = null;
        this.addressNumberTo = null;
        this.description = null;
        this.visualizationNotes = null;
        this.descriptionForCitizens = null;
        this.effectiveStartDate = null;
        this.effectiveEndDate = null;
        this.estimatedStartDate = null;
        this.estimatedEndDate = null;
        this.companyId = null;
        this.notes = null;
        this.isVisible = false;
        this.notPlannedStatus = null;
        this.municipalityReferentName = null;
        this.companyReferentName = null;
        this.municipalityReferentPhoneNumber = null;
        this.companyReferentPhoneNumber = null;
        this.link = null;
        this.trafficChangesMeasure = null;
        this.companyIsOperationalUnit = false;
    }
    get referredLink() {
        if (this.link) {
            if (this.link.indexOf("http://") < 0 && this.link.indexOf("https://") < 0)
                return "http://" + this.link;
        }
        return this.link;
    }
    get extendedAddressName() {
        if (this.addressNumberFrom && this.addressNumberTo)
            return this.address + " dal civico " + this.addressNumberFrom + " al civico " + this.addressNumberTo;
        else if (!this.addressNumberFrom && this.addressNumberTo)
            return this.address + " dal civico 1 al civico " + this.addressNumberTo;
        else if (this.addressNumberFrom && !this.addressNumberTo)
            return this.address + " dal civico " + this.addressNumberFrom;
        else
            return this.address;
    }
}
export class RoadWorkItem extends RoadWorkBase {
    constructor() {
        super(...arguments);
        this.id = 0;
        this.roadways = [];
        this.companyName = null;
        this.neighborhoods = [];
        this.status = null;
    }
}
export class RoadWorkWrite extends RoadWorkBase {
    constructor() {
        super(...arguments);
        this.id = 0;
        this.addressPoint = [];
        this.roadways = [];
        this.section = [];
        this.neighborhoods = [];
        this.geoFeatureContainer = null;
        this.addressPointFrom = null;
        this.addressPointTo = null;
        this.pinPoint = null;
    }
}
export class RoadWorkMapItem extends RoadWorkBase {
    constructor() {
        super(...arguments);
        this.id = 0;
        this.roadways = [];
        this.companyName = null;
        this.neighborhoods = [];
        this.status = null;
        this.geoFeatureContainer = new GeoFeatureContainer();
    }
}
export class RoadWorkDetail extends RoadWorkBase {
    constructor() {
        super(...arguments);
        this.id = 0;
        this.addressPoint = [];
        this.companyName = null;
        this.roadways = [];
        this.section = [];
        this.neighborhoods = [];
        this.geoFeatureContainer = new GeoFeatureContainer();
        this.addressPointFrom = null;
        this.addressPointTo = null;
        this.pinPoint = null;
        this.status = null;
    }
}
export class RoadWorkFilters {
    constructor() {
        this.id = null;
        this.company = null;
        this.year = null;
        this.neighborhood = null;
        this.roadwayName = null;
        this.description = null;
        this.status = null;
        this.priorities = null;
        this.isOverlap = null;
        this.addressName = null;
        this.effectiveStartDateFrom = null;
        this.effectiveStartDateTo = null;
        this.effectiveEndDateFrom = null;
        this.effectiveEndDateTo = null;
        this.category = null;
        this.companyType = null;
        this.visibleType = null;
    }
}
export class SelectListitem {
    constructor(id = null, label = null) {
        this.id = id;
        this.label = label;
    }
}
export class RoadWorkAttachmentBase {
    constructor() {
        this.id = 0;
        this.isPublic = false;
    }
}
export class RoadWorkAttachmentInfo extends RoadWorkAttachmentBase {
    constructor() {
        super(...arguments);
        this.name = null;
        this.contentType = null;
    }
}
export class Years {
    constructor() {
        this.isDefault = false;
    }
}
export var RoadWorkStatus;
(function (RoadWorkStatus) {
    RoadWorkStatus["Completed"] = "Completed";
    RoadWorkStatus["Deleted"] = "Deleted";
    RoadWorkStatus["InProgress"] = "InProgress";
    RoadWorkStatus["NotStarted"] = "NotStarted";
    RoadWorkStatus["ComingSoon"] = "ComingSoon";
})(RoadWorkStatus || (RoadWorkStatus = {}));
export var CompanyTypes;
(function (CompanyTypes) {
    CompanyTypes["Company"] = "Company";
    CompanyTypes["OperationalUnit"] = "OperationalUnit";
})(CompanyTypes || (CompanyTypes = {}));
export var VisibleTypes;
(function (VisibleTypes) {
    VisibleTypes["Yes"] = "Published";
    VisibleTypes["Not"] = "NotPublished";
})(VisibleTypes || (VisibleTypes = {}));
export var RoadWorkCategories;
(function (RoadWorkCategories) {
    RoadWorkCategories["Planned"] = "Planned";
    RoadWorkCategories["NotPlanned"] = "NotPlanned";
})(RoadWorkCategories || (RoadWorkCategories = {}));
export var NotPlannedCategoryStatus;
(function (NotPlannedCategoryStatus) {
    NotPlannedCategoryStatus["Proposed"] = "Proposed";
    NotPlannedCategoryStatus["Approved"] = "Approved";
    NotPlannedCategoryStatus["Authorized"] = "Authorized";
})(NotPlannedCategoryStatus || (NotPlannedCategoryStatus = {}));
export var OverlapTypes;
(function (OverlapTypes) {
    OverlapTypes["Yes"] = "Yes";
    OverlapTypes["Not"] = "Not";
})(OverlapTypes || (OverlapTypes = {}));
export var PriorityTypes;
(function (PriorityTypes) {
    PriorityTypes["Top"] = "Top";
    PriorityTypes["Secondary"] = "Secondary";
    PriorityTypes["Third"] = "Third";
    PriorityTypes["Equal"] = "Equal";
})(PriorityTypes || (PriorityTypes = {}));
export class SearchResult {
    constructor() {
        this.result = [];
        this.totalCount = 0;
    }
}
export class SearchCriteria {
    constructor() {
        this.pagingCriteria = new PagingCriteria();
        this.orderCriteria = [];
    }
}
export class PagingCriteria {
    constructor() {
        this.itemsPerPage = 10;
        this.pageNumber = 1;
    }
}
export class AddressNumber {
}
export class AuditInfo {
    constructor() {
        this.createdBy = null;
        this.createdAt = null;
        this.updatedBy = null;
        this.lastUpdate = null;
    }
}
export class InterventionTypeCitizenModel {
    constructor() {
        this.icons = [];
    }
}
export class IconCitizenModel {
}
